import React from 'react'
import {Button, Modal} from 'react-bootstrap'
import {Link} from 'gatsby'
import 'components/css/modal.css'
import {Span} from 'components'

function Sales() {
  return (
    <div className="modal-view">
      <Link to="/careers" state={{ modal: false }}>
        <Button className="close" id="close-button">
         <Span aria-hidden="true">x</Span>
        </Button>
      </Link>

      <h2>FINANCIAL ANALYST</h2><hr/>
    <br/>
      <Modal.Title><h4>MINIMUM QUALIFICATIONS</h4></Modal.Title>
      <Modal.Body>
        <ul>
          <li>A bachelor’s degree in Finance/Accountancy/Banking/Economics</li>
          <li>At least 5 years work experience related to financial analysis</li>
          <li>Strong working knowledge of Excel and financial modeling</li>
          <li>Excellent analytical, decision-making, and problem-solving skills</li>
          <li>Attention to accuracy and detail required</li>
          <li>Experience in Investor Relations, Investment Research, Investment Banking and Investor Presentations is an advantage</li>
          <li>Knowledge in SQL is an advantage</li>
        </ul>
      </Modal.Body>
      <a href="mailto: careers@digipay.ph" className="resume"><Button className="resume-button">Send Resume</Button></a>
    </div>
  )
}

export default Sales
